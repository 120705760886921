const locationLowercaseItems = ["of", "and"];
let checkLocationLetterCase = function (string) {
	if (string == null) {
		return string;
	}
	let split_string = string.split(" ");
	for (let i in split_string) {
		if (!locationLowercaseItems.includes(split_string[i])) {
			split_string[i] = split_string[i].charAt(0).toUpperCase() + split_string[i].slice(1);
		}
	}
	return split_string.join(" ");
};

let checkIndustryLetterCase = function (string) {
	if (string == null) {
		return string;
	}
	return string.charAt(0).toUpperCase() + string.slice(1);
};

exports.checkLocationLetterCase = checkLocationLetterCase;
exports.checkIndustryLetterCase = checkIndustryLetterCase;
