import axios from "axios";
import store from "@/store";
import env from "@/environment";

const BASE_URL = env.fsiProfessorAPIBaseURL;
const API_VERSION = env.fsiProfessorAPIVersion;

class FSIProfessorClient {
	searchDomains(domains = []) {
		let resourceURL = "/fsi-professor/domain-entities/search";
		let payload = {
			domains: domains,
		};
		return this.axios.post(resourceURL, payload);
	}

	getReferenceScore(domains = []) {
		let resourceURL = "/fsi-professor/domain-entities/digital-reference-check";
		let payload = {
			domains: domains,
		};
		return this.axios.post(resourceURL, payload);
	}

	constructor() {
		this.baseUrl = `${BASE_URL}/${API_VERSION}`;
		this.init();
	}

	/**
	 * Initialize Axios defaults and API Map
	 */
	init() {
		this.setupAxios();
	}

	/**
	 * Setup axios
	 */
	setupAxios() {
		this.axios = axios.create({ baseURL: this.baseUrl });

		this.axios.interceptors.request.use(
			(config) => {
				const token = store.getters.auth.token;

				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				} else {
					throw new axios.Cancel(`Operation canceled by the user \n 
                                            Reson: no auth token present`);
				}

				return config;
			},
			(error) => {
				console.log(`No auth token present @ ApiProvider`);
				console.log(error);
				Promise.reject(error);
			}
		);
	}
}

const fsiprofessorapi = new FSIProfessorClient();
export default fsiprofessorapi;
