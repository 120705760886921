<template>
	<div>
		<div class="w-12/12">
			<div class="flex flex-col md:flex-row md:justify-between">
				<div class="m-3 md:w-5/12">
					<div class="font-light text-2xl tracking-widest border-b-2">
						<span class="text-3xl mr-3">//</span>{{ prospectInfo["company-info"].name }}
					</div>
					<!-- <img v-if="faviconLink" :src="faviconLink" alt="company logo" /> -->
					<!-- <img v-if="prospectInfo.domain" :src="'https://logo.clearbit.com/' + prospectInfo.domain"> -->

					<!-- <div class="mt-8">
                        <div class="font-bold text-lg">
                            Description
                        </div>
                        <div class="text-md">
                            {{prospectInfo.info.description}}
                        </div>
                    </div> -->

					<div class="md:w-8/12 my-5">
						<div class="flex justify-between my-2 items-center">
							<div class="font-bold">Company number:</div>
							<div class="text-sm">
								<span v-if="prospectInfo['company-info']['company-number']">{{
									prospectInfo["company-info"]["company-number"]
								}}</span>
								<span v-if="!prospectInfo['company-info']['company-number']">-</span>
							</div>
						</div>
						<div class="flex justify-between my-2 items-center">
							<div class="font-bold">Location:</div>
							<div class="text-sm">
								<span v-if="prospectInfo['location'].region">
									{{ checkLocationLetterCase(prospectInfo["location"].region) }}
									<span class="ml-1"
										>(<a
											class="text-sm underline hover:no-underline text-blue-600"
											:href="
												'https://www.google.co.uk/maps/place/' + prospectInfo['location'].region
											"
											target="_blank"
											>map</a
										>
										<span class="inline-block" v-html="link_icon"></span>)</span
									>
								</span>
								<span v-if="!prospectInfo['location'].region">-</span>
							</div>
						</div>
						<div class="flex justify-between my-2 items-center">
							<div class="font-bold">Industry:</div>
							<div class="text-sm">
								<span v-if="prospectInfo['industrial-classification'].industry">{{
									checkIndustryLetterCase(prospectInfo["industrial-classification"].industry)
								}}</span>
								<span v-if="!prospectInfo['industrial-classification'].industry">-</span>
							</div>
						</div>
						<div class="flex justify-between my-2 items-center">
							<div class="font-bold">Size <span class="text-sm">(employees)</span>:</div>
							<div class="text-sm">
								<span v-if="prospectInfo['size'].employees">{{ prospectInfo["size"].employees }}</span>
								<span v-if="!prospectInfo['size'].employees">-</span>
							</div>
						</div>
						<div class="flex justify-between my-2 items-center">
							<div class="font-bold">InnovationNet <span class="text-sm">(grade)</span>:</div>
							<div
								v-if="prospectInfo['innovation-net'].grade == 'A'"
								class="w-10 text-center text-sm rounded-full text-white font-black bg-green-700">
								{{ prospectInfo["innovation-net"].grade }}
							</div>
							<div
								v-if="prospectInfo['innovation-net'].grade == 'B'"
								class="w-10 text-center text-sm rounded-full text-white font-black bg-green-500">
								{{ prospectInfo["innovation-net"].grade }}
							</div>
							<div
								v-if="prospectInfo['innovation-net'].grade == 'C'"
								class="w-10 text-center text-sm rounded-full text-white font-black bg-yellow-300">
								{{ prospectInfo["innovation-net"].grade }}
							</div>
							<div
								v-if="prospectInfo['innovation-net'].grade == 'D'"
								class="w-10 text-center text-sm rounded-full text-white font-black bg-yellow-500">
								{{ prospectInfo["innovation-net"].grade }}
							</div>
							<div
								v-if="prospectInfo['innovation-net'].grade == 'E'"
								class="w-10 text-center text-sm rounded-full text-white font-black bg-red-600">
								{{ prospectInfo["innovation-net"].grade }}
							</div>
						</div>
					</div>
				</div>

				<div class="m-3 md:w-5/12">
					<div class="mt-8">
						<div class="font-bold text-lg">Website</div>
						<div class="text-md mb-5">
							<a
								class="text-sm underline hover:no-underline text-blue-600"
								:href="'http://' + prospectInfo['domain']"
								target="_blank"
								>{{ prospectInfo["domain"] }}</a
							>
						</div>

						<div class="font-bold text-lg">LinkedIn</div>
						<div class="text-md mb-5">
							<a
								v-if="prospectInfo['social']['linkedin-profile-url']"
								class="text-sm underline hover:no-underline text-blue-600"
								:href="'https://' + prospectInfo['social']['linkedin-profile-url']"
								target="_blank"
								>{{ prospectInfo["social"]["linkedin-profile-url"] }}</a
							>
							<span v-if="!prospectInfo['social']['linkedin-profile-url']">-</span>
						</div>

						<div class="font-bold text-lg">Companies House</div>
						<div class="text-md mb-5">
							<a
								v-if="prospectInfo['company-info']['company-number']"
								class="text-sm underline hover:no-underline text-blue-600"
								:href="
									'https://find-and-update.company-information.service.gov.uk/company/' +
									prospectInfo['company-info']['company-number']
								"
								target="_blank"
								>{{
									"find-and-update.company-information.service.gov.uk/company/" +
									prospectInfo["company-info"]["company-number"]
								}}</a
							>
							<span v-if="!prospectInfo['company-info']['company-number']">-</span>
						</div>
					</div>
				</div>
			</div>

			<div class="m-3">
				<div class="my-5">
					<div class="font-light text-2xl tracking-wide">Innovate UK grants</div>
					<div v-if="prospectInfo['innovateuk']['grants-awarded']">
						<div
							v-for="(project_number, index) in prospectInfo['innovateuk']['grants-awarded']"
							:key="index">
							<a
								class="text-sm underline hover:no-underline text-blue-600"
								:href="'https://gtr.ukri.org/projects?ref=' + project_number"
								target="_blank"
								>{{ "gtr.ukri.org/projects?ref=" + project_number }}</a
							>
						</div>
					</div>
					<span
						v-if="
							prospectInfo['innovateuk']['grants-awarded'] &&
							prospectInfo['innovateuk']['grants-awarded'].length == 0
						"
						>-</span
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import fsiprofessorapi from "@/services/api-clients/fsiprofessor";
import { checkLocationLetterCase, checkIndustryLetterCase } from "@/utils";

const link_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>`;

const baseProspectInfo = {
	domain: null,
	"company-info": {
		name: null,
		"company-number": null,
	},
	location: {
		region: null,
	},
	"industrial-classification": {
		industry: null,
	},
	size: {
		employees: null,
	},
	"innovation-net": {
		score: null,
	},
	innovateuk: {
		"grants-awarded": null,
	},
	social: {
		"linkedin-profile-url": null,
	},
};

export default {
	created() {},

	mounted() {
		let domain = this.prospectId;
		this.getProspectInfo([domain]);
	},

	components: {},

	props: ["prospectId"],

	data() {
		return {
			checkLocationLetterCase,
			checkIndustryLetterCase,
			link_icon,
			prospectInfo: baseProspectInfo,
		};
	},

	methods: {
		async getProspectInfo(domains) {
			let fsi_prof_response = await fsiprofessorapi.searchDomains(domains);
			let fsi_prof_data = fsi_prof_response.data.domain_entities;

			if (fsi_prof_data.length == 1) {
				fsi_prof_data = fsi_prof_data[0];
			} else {
				this.prospectInfo = baseProspectInfo;
				return;
			}

			let _prospectInfo = {};
			for (var i in fsi_prof_data.latest_data_points) {
				_prospectInfo[fsi_prof_data.latest_data_points[i].data_type] =
					fsi_prof_data.latest_data_points[i].payload;
			}

			_prospectInfo["domain"] = fsi_prof_data["domain"];
			if (_prospectInfo["company-info"]) {
				_prospectInfo["company-info"].name = _prospectInfo["company-info"].name.toUpperCase();
			}
			this.prospectInfo = _prospectInfo;
		},
	},

	watch: {},

	computed: {},
};
</script>

<style></style>
